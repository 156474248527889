import React from "react";
import "./App.css";

/**
 * 이 컴포넌트는 탭의 "정보"에서 찾아볼 수 있는 링크인 "개인정보처리방침" 을 표시하기 위해 사용됨
 */
const Privacy: React.FC = () => {
  return (
    <div>
      <h1>개인정보처리방침</h1>
    </div>
  );
};

export default Privacy;
