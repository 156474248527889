import React from "react";
import "./App.css";
import * as microsoftTeams from "@microsoft/teams-js";

// window.location.hash를 dictionary로 변환용 도우미 함수
function getHashParameters() {
  let hashParams: Record<string, string> = {};
  window.location.hash
    .substr(1)
    .split("&")
    .forEach(function (item) {
      let [key, value] = item.split("=");
      hashParams[key] = decodeURIComponent(value);
    });
  return hashParams;
}

/**
 * 이 컴포넌트는 암시적 권한 부여 흐름(implicit grant flow)이 완료되면 로드됨
 * 참고: https://docs.microsoft.com/ko-kr/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
 */
const ClosePopup: React.FC = () => {
  // 최초 1회만 수행됨
  React.useEffect(() => {
    microsoftTeams.initialize();

    // 암시적 권한 부여 흐름은 결과를 window.location.hash 객체에 주입하므로, 이를 파싱하여 결과를 찾음
    const hashParams = getHashParameters();

    // 만약 사용자 동의가 성공적으로 수행되었다면, 그래프 엑세스 토큰이 dictionary 안에 필드로서 존재함
    if (hashParams["access_token"]) {
      // Tab 컴포넌트의 showConsentDialog 함수에 인증이 성공했음을 알려서 성공 콜백이 호출되도록 함
      microsoftTeams.authentication.notifySuccess(hashParams["access_token"]);
    } else {
      microsoftTeams.authentication.notifyFailure("Consent failed");
    }
  }, []);

  return (
    <div>
      <h1>Consent flow complete.</h1>
    </div>
  );
};

export default ClosePopup;
