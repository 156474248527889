import React from "react";
import {
  Flex,
  Text,
  Table,
  TableRowProps,
  Divider,
  Input,
  SearchIcon,
  Button,
  InfoIcon,
  EmailIcon,
  ChatIcon,
  Popup,
  Card,
  CardHeader,
  Avatar,
  CardBody,
  ShorthandValue,
} from "@fluentui/react-northstar";
import { OrgChartPageContext } from "./Tab";
import { useTranslation } from "react-i18next";
import * as msTeams from "../msTeams";
import { OrgChartDataItem } from "../../core/orgChart";
import { debounce } from "lodash";

const DeptUsers: React.FC = () => {
  console.log("DeptUsers 렌더링됨");

  const { orgChartData, selectedDept, chatUsers, setChatUsers, featureToggles, searchKeyword } = React.useContext(OrgChartPageContext);
  const { t } = useTranslation();

  // 사용자 검색 키워드
  const [userSearchKeyword, setUserSearchKeyword] = React.useState("");

  // 테이블 행 클릭 이벤트 핸들러
  const handleTableRowClick = React.useCallback(
    (orgChartDataItem: OrgChartDataItem) => {
      if (setChatUsers === undefined) return;

      // 기존 챗 유저들
      const oldChatUsers = chatUsers ?? [];

      const newChatUsers = (oldChatUsers as { key: string; header: string; content: string }[])
        .concat([
          { key: `chatUser-${orgChartDataItem.treeNodeIndex}`, header: orgChartDataItem.userInfo?.principalInfo?.title ?? "", content: orgChartDataItem.userInfo?.email ?? "" },
        ])
        // 중복 제거
        .filter((x, i, arr) => arr.map((x) => x.key).indexOf(x.key) === i);

      // 채팅 사용자 추가
      setChatUsers(newChatUsers);
    },
    [chatUsers, setChatUsers]
  );

  // 사용자 테이블 헤더
  const [userTableHeaders, setUserTableHeaders] = React.useState<ShorthandValue<TableRowProps>>();
  // 사용자 테이블 헤더 확보: 번역이 변경될 때 마다 수행됨
  React.useEffect(() => {
    const key = "userTableHeader";
    setUserTableHeaders({
      key: key,
      items: [
        {
          key: `${key}-name`,
          content: t("이름"),
          styles: { flexGrow: 4 },
        },
        {
          key: `${key}-jobTitle`,
          content: t("직위"),
          styles: { flexGrow: 1 },
        },
        {
          key: `${key}-officePhone`,
          content: t("내선번호"),
          styles: { flexGrow: 2 },
        },
        {
          key: `${key}-mobilePhone`,
          content: t("휴대폰번호"),
          styles: { flexGrow: 2 },
        },
      ],
    });
  }, [t]);

  // 사용자 테이블 항목들
  const [userTableItems, setUserTableItems] = React.useState<TableRowProps[]>([]);
  // 사용자 테이블 항목들 확보: 선택된 부서가 변경될 때 마다 수행됨
  React.useEffect(() => {
    // 조직 데이터가 아직 없거나 선택된 부서가 없는경우 아무것도 안함
    if (orgChartData === undefined || selectedDept === undefined) return;

    const userTableItems = orgChartData
      // 필터: 선택된 부서의 바로 자식 노드중 사용자 타입 노드만 추림
      .filter((x) => {
        return x.treeNodeIndex.startsWith(selectedDept?.treeNodeIndex) && x.treeNodeDepth === selectedDept?.treeNodeDepth + 1 && x.type === "User";
      })
      .map((x) => {
        const rowItemKey = `userTableItem-${x.treeNodeIndex}`;
        // 이름
        const title = x.userInfo?.principalInfo?.title ?? x.title;
        // 직위
        const jobTitle = x.userInfo?.jobTitle ?? "";
        // 내선번호
        const officePhone = x.userInfo?.officePhone ?? "";
        // 휴대폰번호
        const mobilePhone = x.userInfo?.mobilePhone ?? "";
        // 이메일
        const email = x.userInfo?.email ?? "";

        // 이름, 직위, 내선번호, 휴대폰번호
        return {
          key: rowItemKey,
          items: [
            {
              key: `${rowItemKey}-title`,
              content: (
                <Flex gap="gap.large" vAlign="center" wrap>
                  <Text size="small">{x.userInfo?.principalInfo?.title ?? x.title}</Text>
                  <Button.Group>
                    <Popup
                      styles={{ padding: 0 }}
                      trigger={
                        <Button
                          icon={<InfoIcon size="medium" />}
                          text
                          iconOnly
                          title={t("정보")}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ height: "initial", minWidth: "initial" }}
                        />
                      }
                      content={
                        <Card quiet style={{ cursor: "initial" }} onClick={(e) => e.stopPropagation()}>
                          <CardHeader>
                            <Flex gap="gap.small" space="between">
                              <Flex column>
                                <Text content={title} weight="bold" />
                                <Text content={jobTitle} size="small" />
                              </Flex>
                              <Avatar label={title} name={jobTitle} />
                            </Flex>
                          </CardHeader>
                          <CardBody>
                            <Flex column gap="gap.small">
                              <Flex column>
                                <Text content={email} size="small" />
                                <Text content={officePhone} size="small" />
                                <Text content={mobilePhone} size="small" />
                              </Flex>
                              <Text content={t("사용자를 클릭하여 그룹 채팅에 초대할 수 있습니다.")} size="small" />
                              <Flex>
                                <Button
                                  icon={<ChatIcon size="medium" />}
                                  text
                                  iconOnly
                                  title={t("1:1 대화", { nsSeparator: "|" })}
                                  style={{ height: "initial", minWidth: "initial" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    msTeams.executeChatDeepLink(email);
                                  }}
                                />
                                <Button
                                  icon={<EmailIcon size="medium" />}
                                  text
                                  iconOnly
                                  title={t("메일")}
                                  style={{ height: "initial", minWidth: "initial" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(`mailto://${email}`, "_blank");
                                  }}
                                />
                              </Flex>
                            </Flex>
                          </CardBody>
                        </Card>
                      }
                    />
                    <Button
                      icon={<ChatIcon size="medium" />}
                      text
                      iconOnly
                      title={t("1:1 대화", { nsSeparator: "|" })}
                      style={{ height: "initial", minWidth: "initial" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        msTeams.executeChatDeepLink(email);
                      }}
                    />
                    <Button
                      icon={<EmailIcon size="medium" />}
                      text
                      iconOnly
                      title={t("메일")}
                      style={{ height: "initial", minWidth: "initial" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`mailto://${email}`, "_blank");
                      }}
                    />
                  </Button.Group>
                </Flex>
              ),
              styles: { flexGrow: 4 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-jobTitle`,
              content: jobTitle,
              styles: { flexGrow: 1 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-officePhone`,
              content: officePhone,
              styles: { flexGrow: 2 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-mobilePhone`,
              content: mobilePhone,
              styles: { flexGrow: 2 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
          ],
          styles: { cursor: "pointer" },
        };
      });

    setUserTableItems(userTableItems);
    console.log("사용자 테이블 항목들 설정됨", userTableItems);
  }, [orgChartData, selectedDept, t, handleTableRowClick]);

  // 사용자 검색 결과 테이블 헤더
  const [userSearchResultTableHeaders, setSearchResultUserTableHeaders] = React.useState<ShorthandValue<TableRowProps>>();
  // 사용자 검색결과 테이블 헤더 확보: 번역이 변경될 때 마다 수행됨
  React.useEffect(() => {
    const key = "userSearchResultTableHeader";
    setSearchResultUserTableHeaders({
      key: key,
      items: [
        {
          key: `${key}-dept`,
          content: t("부서"),
          styles: { flexGrow: 2 },
        },
        {
          key: `${key}-name`,
          content: t("이름"),
          styles: { flexGrow: 4 },
        },
        {
          key: `${key}-jobTitle`,
          content: t("직위"),
          styles: { flexGrow: 1 },
        },
        {
          key: `${key}-officePhone`,
          content: t("내선번호"),
          styles: { flexGrow: 2 },
        },
        {
          key: `${key}-mobilePhone`,
          content: t("휴대폰번호"),
          styles: { flexGrow: 2 },
        },
      ],
    });
  }, [t]);

  // 사용자 검색 결과 테이블 항목들
  const [userSearchResultTableItems, setUserSearchResultTableItems] = React.useState<TableRowProps[]>([]);
  // 사용자 검색 결과 테이블 항목들 확보: 사용자 검색 키워드가 변경될 때 마다 수행됨
  React.useEffect(() => {
    if (orgChartData === undefined) return;
    if (orgChartData.length === 0) return;

    if (userSearchKeyword === "") {
      setUserSearchResultTableItems([]);
      return;
    }

    // 사용자 검색 결과 테이블 항목들
    const userSearchResultTableItems = orgChartData
      .filter((x) => x.type === "User")
      .filter(
        (x) =>
          // 부서(대소문자 구분 X) - TODO: 성능 똥망 코드이므로 개선이 필요합니다.
          (orgChartData.find((y) => y.treeNodeDepth === x.treeNodeDepth - 1 && x.treeNodeIndex.startsWith(y.treeNodeIndex) && y.type !== "User")?.title ?? "")
            .toLowerCase()
            .includes(userSearchKeyword.toLowerCase()) ||
          // 이름(대소문자 구분 X)
          x.userInfo?.principalInfo?.title.toLowerCase().includes(userSearchKeyword.toLowerCase()) ||
          // 직위(대소문자 구분 X)
          x.userInfo?.jobTitle?.toLowerCase().includes(userSearchKeyword.toLowerCase()) ||
          // 내선번호
          x.userInfo?.officePhone?.includes(userSearchKeyword) ||
          // 휴대폰번호
          x.userInfo?.mobilePhone?.includes(userSearchKeyword) ||
          // 이메일(대소문자 구분 X)
          x.userInfo?.email?.toLowerCase().includes(userSearchKeyword.toLowerCase())
      )
      .map((x) => {
        // 행 항목 키
        const rowItemKey = `userSearchTableItem-${x.treeNodeIndex}`;

        // 부서명: 부모노드의 title, 부모 노드의 타입이 User 인 경우 빈 값 - TODO: 성능 똥망 코드이므로 개선이 필요합니다.
        const department = orgChartData.find((y) => y.treeNodeDepth === x.treeNodeDepth - 1 && x.treeNodeIndex.startsWith(y.treeNodeIndex) && y.type !== "User")?.title ?? "";
        // 이름
        const title = x.userInfo?.principalInfo?.title ?? x.title;
        // 직위
        const jobTitle = x.userInfo?.jobTitle ?? "";
        // 내선번호
        const officePhone = x.userInfo?.officePhone ?? "";
        // 휴대폰번호
        const mobilePhone = x.userInfo?.mobilePhone ?? "";
        // 이메일
        const email = x.userInfo?.email ?? "";

        // 부서, 이름, 직위, 내선번호, 휴대폰번호
        return {
          key: rowItemKey,
          items: [
            {
              key: `${rowItemKey}-department`,
              content: department,
              styles: { flexGrow: 2 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-title`,
              content: (
                <Flex gap="gap.large" vAlign="center" wrap>
                  <Text size="small">{x.userInfo?.principalInfo?.title ?? x.title}</Text>
                  <Button.Group>
                    <Popup
                      trigger={
                        <Button
                          icon={<InfoIcon size="medium" />}
                          text
                          iconOnly
                          title={t("정보")}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ height: "initial", minWidth: "initial" }}
                        />
                      }
                      content={
                        <Card quiet>
                          <CardHeader>
                            <Flex gap="gap.small" space="between">
                              <Flex column>
                                <Text content={title} weight="bold" />
                                <Text content={jobTitle} size="small" />
                              </Flex>
                              <Avatar label={title} name={jobTitle} />
                            </Flex>
                          </CardHeader>
                          <CardBody>
                            <Flex column gap="gap.small">
                              <Flex column>
                                <Text content={email} size="small" />
                                <Text content={officePhone} size="small" />
                                <Text content={mobilePhone} size="small" />
                              </Flex>
                              <Text content={t("사용자를 클릭하여 그룹 채팅에 초대할 수 있습니다.")} size="small" />
                              <Flex>
                                <Button
                                  icon={<ChatIcon size="medium" />}
                                  text
                                  iconOnly
                                  title={t("1:1 대화", { nsSeparator: "|" })}
                                  style={{ height: "initial", minWidth: "initial" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    msTeams.executeChatDeepLink(email);
                                  }}
                                />
                                <Button
                                  icon={<EmailIcon size="medium" />}
                                  text
                                  iconOnly
                                  title={t("메일")}
                                  style={{ height: "initial", minWidth: "initial" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(`mailto://${email}`, "_blank");
                                  }}
                                />
                              </Flex>
                            </Flex>
                          </CardBody>
                        </Card>
                      }
                    />
                    <Button
                      icon={<ChatIcon size="medium" />}
                      text
                      iconOnly
                      title={t("1:1 대화", { nsSeparator: "|" })}
                      style={{ height: "initial", minWidth: "initial" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        msTeams.executeChatDeepLink(email);
                      }}
                    />
                    <Button
                      icon={<EmailIcon size="medium" />}
                      text
                      iconOnly
                      title={t("메일")}
                      style={{ height: "initial", minWidth: "initial" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`mailto://${email}`, "_blank");
                      }}
                    />
                  </Button.Group>
                </Flex>
              ),
              styles: { flexGrow: 4 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-jobTitle`,
              content: jobTitle,
              styles: { flexGrow: 1 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-officePhone`,
              content: officePhone,
              styles: { flexGrow: 2 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
            {
              key: `${rowItemKey}-mobilePhone`,
              content: mobilePhone,
              styles: { flexGrow: 2 },
              onClick: () => {
                handleTableRowClick(x);
              },
            },
          ],
          styles: { cursor: "pointer" },
        };
      });
    setUserSearchResultTableItems(userSearchResultTableItems);
  }, [orgChartData, userSearchKeyword, handleTableRowClick, t]);

  // 검색 키워드가 변경될 때 마다 수행됨
  React.useEffect(() => {
    if (searchKeyword === undefined) return;

    setUserSearchKeyword(searchKeyword);
  }, [searchKeyword]);

  // 사용자 검색 onChange 핸들러
  const onChangeHandler = debounce((value: string) => {
    setUserSearchKeyword(value);
  }, 200);

  return (
    <Flex column>
      <Flex>
        <Text size="larger" content={userSearchKeyword === "" ? selectedDept?.title ?? "　" : t("검색 결과")} />
        {featureToggles?.searchv2 === false ? (
          <Flex.Item size="size.half" push>
            <Input icon={<SearchIcon />} fluid clearable placeholder={t("사용자 검색...")} onChange={(e, d) => onChangeHandler(d?.value ?? "")} />
          </Flex.Item>
        ) : null}
      </Flex>
      <Divider />
      <Table header={userSearchKeyword === "" ? userTableHeaders : userSearchResultTableHeaders} rows={userSearchKeyword === "" ? userTableItems : userSearchResultTableItems} />
    </Flex>
  );
};

export default DeptUsers;
