import React from "react";
import { Input, SearchIcon } from "@fluentui/react-northstar";
import { OrgChartPageContext } from "./Tab";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const SearchDept: React.FC = () => {
  const { setDeptSearchKeyword } = React.useContext(OrgChartPageContext);
  const { t } = useTranslation();

  const onChangeHandler = debounce((value: string) => {
    setDeptSearchKeyword && setDeptSearchKeyword(value);
  }, 200);

  return <Input icon={<SearchIcon />} fluid clearable placeholder={t("부서 검색...")} onChange={(e, v) => onChangeHandler(v?.value ?? "")} />;
};

export default SearchDept;
