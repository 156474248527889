import React from "react";
import { Text, ContactGroupIcon, GalleryIcon } from "@fluentui/react-northstar";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import { IndeterminateCheckBoxOutlined, AddBoxOutlined, ExpandMore, ChevronRight } from "@material-ui/icons";
import { TreeView, TreeItem } from "@material-ui/lab";
import { OrgChartNode } from "../../core/orgChart";
import { OrgChartPageContext } from "./Tab";
import { useTranslation } from "react-i18next";
import "./DeptTree.css";

// Material UI 테마 - 애니메이션 효과를 전역적으로 끄기위해 사용
const muiTheme = createMuiTheme({
  transitions: {
    // 모든곳에서 `transition: none;` 스타일 적용
    create: () => "none",
  },
});

// 트리에 적용될 스타일 오버라이드
const useMuiStyles = makeStyles({
  root: {
    // 드래그로 선택되는거 막음
    userSelect: "none",
  },
  content: {
    // 접기/펼치기 아이콘이 없는 빈칸에도 커서가 손가락으로 나오는 현상 방지용(1/3)
    cursor: "initial",
  },
  iconContainer: {
    // 접기/펼치기 아이콘이 없는 빈칸에도 커서가 손가락으로 나오는 현상 방지용(2/3)
    cursor: "pointer",
  },
  label: {
    // 접기/펼치기 아이콘이 없는 빈칸에도 커서가 손가락으로 나오는 현상 방지용(3/3)
    cursor: "pointer",
  },
});

// 검색에서 사용될 스타일
const searchHilightedStyles = {
  default: {
    color: "#252423",
    backgroundColor: "#e2e2f6",
  },
  dark: {
    color: "#fff",
    backgroundColor: "#464775",
  },
  contrast: {
    color: "#000",
    backgroundColor: "#fff",
  },
};

const DeptTree: React.FC = () => {
  console.log("DeptTree 렌더링됨");

  const {
    featureToggles,
    theme,
    orgChartData,
    rootNode,
    searchKeyword,
    deptSearchKeyword,
    selectedDept,
    setSelectedDept,
    chatUsers,
    setChatUsers,
    setChatTopicName,
  } = React.useContext(OrgChartPageContext);
  const { t } = useTranslation();

  // Material UI 클래스들
  const muiClasses = useMuiStyles();

  // 검색 하이라이트 스타일
  const [searchHighlitedStyle, setSearchHilightedStyle] = React.useState(searchHilightedStyles.default);
  // 검색 하이라이트 스타일 확보: 테마가 변경될 때 마다 수행됨
  React.useEffect(() => {
    switch (theme) {
      case "dark":
        setSearchHilightedStyle(searchHilightedStyles.dark);
        break;
      case "contrast":
        setSearchHilightedStyle(searchHilightedStyles.contrast);
        break;
      case "default":
      default:
        setSearchHilightedStyle(searchHilightedStyles.default);
    }
  }, [theme]);

  const handleDoubleClick = React.useCallback(() => {
    if (orgChartData === undefined) return;
    if (selectedDept === undefined) return;
    if (setChatUsers === undefined) return;
    if (setChatTopicName === undefined) return;

    // 기존 챗 유저들
    const oldChatUsers = chatUsers ?? [];

    const selectedDeptUsers = orgChartData
      .filter((x) => x.type === "User" && x.treeNodeIndex.startsWith(selectedDept.treeNodeIndex) && x.treeNodeDepth === selectedDept.treeNodeDepth + 1)
      .map((x) => {
        return { key: `chatUser-${x.treeNodeIndex}`, header: x.userInfo?.principalInfo?.title ?? "", content: x.userInfo?.email ?? "" };
      });

    const newChatUsers = (oldChatUsers as { key: string; header: string; content: string }[])
      .concat(selectedDeptUsers)
      // 중복 제거
      .filter((x, i, arr) => arr.map((x) => x.key).indexOf(x.key) === i);

    // 채팅 사용자 추가
    setChatUsers(newChatUsers);
    // 채팅 이름 변경
    setChatTopicName(selectedDept?.title ?? "");
  }, [orgChartData, selectedDept, chatUsers, setChatUsers, setChatTopicName]);

  const toDeptTreeItem = React.useCallback(
    (node: OrgChartNode, searchKeyword = "") => {
      const labelText = (
        <Text size="medium">
          {searchKeyword === ""
            ? node.label
            : // 검색어 하이라이트: https://stackoverflow.com/a/43235785
              node.label.split(new RegExp(`(${searchKeyword})`, "gi")).map((strPart, i) => {
                return (
                  <span key={i} style={strPart.toLowerCase() === searchKeyword.toLowerCase() ? searchHighlitedStyle : {}}>
                    {strPart}
                  </span>
                );
              })}
        </Text>
      );

      return (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          title={t("더블 클릭하여 그룹 채팅에 추가")}
          label={
            <div>
              <span>{featureToggles?.newDeptIcon ? <ContactGroupIcon /> : <GalleryIcon />}</span>
              <Text size="medium"> {labelText}</Text>
            </div>
          }
          // 스타일 적용: 보다 자연스러운 커서표시를 위함 - 접기/펼치기 아이콘이 없는 항목에서 아이콘 영역에 커서가 손가락으로 나오는 현상 방지
          classes={{
            content: muiClasses.content,
            label: muiClasses.label,
            iconContainer: muiClasses.iconContainer,
          }}
          // 레이블 클릭시 onNodeToggle 이 호출되는것을 차단 - https://material-ui.com/api/tree-item/
          onLabelClick={(e) => e.preventDefault()}
          onDoubleClick={(e) => {
            e.stopPropagation();
            handleDoubleClick();
          }}
        >
          {node.children?.filter((x) => x.type === "Department").map((x) => toDeptTreeItem(x, searchKeyword))}
        </TreeItem>
      );
    },
    [muiClasses, searchHighlitedStyle, featureToggles, handleDoubleClick, t]
  );

  // 부서 트리 항목들
  const [deptTreeItems, setDeptTreeItems] = React.useState<JSX.Element | null>(null);
  // 부서 트리 항목들 확보: 루트 노드 데이터가 변경될 때마다 수행됨
  React.useEffect(() => {
    if (rootNode === undefined) return;

    const deptTreeItems: JSX.Element = toDeptTreeItem(rootNode);
    setDeptTreeItems(deptTreeItems);
  }, [rootNode, toDeptTreeItem]);

  // 확장된 부서 검색 결과 노드 ID들
  const [deptSearchResultExpanded, setDeptSearchResultExpanded] = React.useState<string[]>(["0"]);
  // 부서 검색 결과 트리 항목들
  const [deptSearchResultTreeItems, setDeptSearchResultTreeItems] = React.useState<JSX.Element | null>(null);
  // 부서 검색 결과 트리 항목들 확보: 부서 검색 키워드가 변경될 때마다 수행됨
  React.useEffect(() => {
    if (orgChartData === undefined) return;
    if (rootNode === undefined) return;
    if (deptSearchKeyword === undefined || deptSearchKeyword === "") {
      setDeptSearchResultTreeItems(null);
      return;
    }

    // 부모 노드 ID 가져오기
    function getParentNodeId(id: string) {
      if (id.indexOf(".") === -1) return null;
      const idChunk = id.split(".");
      idChunk.pop();
      return idChunk.join(".");
    }
    // 부모 노드 ID들 가져오기
    function getParentNodeIds(id: string) {
      let parentNodeIds: string[] = [];
      const parentNodeId = getParentNodeId(id);
      if (parentNodeId !== null) {
        parentNodeIds = [parentNodeId, ...getParentNodeIds(parentNodeId)];
      }
      // 중복 제거해서 반환: https://stackoverflow.com/a/33121880
      return Array.from(new Set(parentNodeIds));
    }

    // 검색 히트한 노드들의 모든 부모 노드 ID들
    const searchHitParentNodeIds = orgChartData
      ?.filter((x) => x.type === "Root" || x.type === "Department")
      .filter((x) => x.title.toLowerCase().includes(deptSearchKeyword.toLowerCase()))
      .map((x) => x.treeNodeIndex)
      .flatMap((id) => getParentNodeIds(id))
      // 중복제거 https://stackoverflow.com/a/39272754
      .filter((x, i, a) => a.indexOf(x) === i);

    console.log("searchHitParentNodeIds", searchHitParentNodeIds);
    setDeptSearchResultExpanded(searchHitParentNodeIds);

    const deptTreeItems: JSX.Element = toDeptTreeItem(rootNode, deptSearchKeyword);
    setDeptSearchResultTreeItems(deptTreeItems);
  }, [orgChartData, rootNode, toDeptTreeItem, deptSearchKeyword]);

  // 확장된 검색 결과 노드 ID들
  const [searchResultExpanded, setSearchResultExpanded] = React.useState<string[]>(["0"]);
  // 검색 결과 트리 항목들
  const [searchResultTreeItems, setSearchResultTreeItems] = React.useState<JSX.Element | null>(null);
  // 검색 결과 트리 항목들 확보: 검색 키워드가 변경될 때마다 수행됨
  React.useEffect(() => {
    if (orgChartData === undefined) return;
    if (rootNode === undefined) return;
    if (searchKeyword === undefined || searchKeyword === "") {
      setSearchResultTreeItems(null);
      return;
    }

    // 부모 노드 ID 가져오기
    function getParentNodeId(id: string) {
      if (id.indexOf(".") === -1) return null;
      const idChunk = id.split(".");
      idChunk.pop();
      return idChunk.join(".");
    }
    // 부모 노드 ID들 가져오기
    function getParentNodeIds(id: string) {
      let parentNodeIds: string[] = [];
      const parentNodeId = getParentNodeId(id);
      if (parentNodeId !== null) {
        parentNodeIds = [parentNodeId, ...getParentNodeIds(parentNodeId)];
      }
      // 중복 제거해서 반환: https://stackoverflow.com/a/33121880
      return Array.from(new Set(parentNodeIds));
    }

    // 검색 히트한 노드들의 모든 부모 노드 ID들
    const searchHitParentNodeIds = orgChartData
      ?.filter((x) => x.type === "Root" || x.type === "Department")
      .filter((x) => x.title.toLowerCase().includes(searchKeyword.toLowerCase()))
      .map((x) => x.treeNodeIndex)
      .flatMap((id) => getParentNodeIds(id))
      // 중복제거 https://stackoverflow.com/a/39272754
      .filter((x, i, a) => a.indexOf(x) === i);

    console.log("searchHitParentNodeIds", searchHitParentNodeIds);
    setSearchResultExpanded(searchHitParentNodeIds);

    const deptTreeItems: JSX.Element = toDeptTreeItem(rootNode, searchKeyword);
    setSearchResultTreeItems(deptTreeItems);
  }, [orgChartData, rootNode, toDeptTreeItem, searchKeyword]);

  // 확장된 노드 ID들
  const [expanded, setExpanded] = React.useState<string[]>(["0"]);
  // 선택된 노드 ID
  const [selected, setSelected] = React.useState<string>("");

  // 부서 트리 토글됨 핸들러
  const handleToggle = (e: React.ChangeEvent<unknown>, nodeIds: string[]) => {
    featureToggles?.searchv2 === false
      ? deptSearchKeyword === ""
        ? setExpanded(nodeIds)
        : setDeptSearchResultExpanded(nodeIds)
      : searchKeyword === ""
      ? setExpanded(nodeIds)
      : setSearchResultExpanded(nodeIds);
  };
  // 부서 트리 선택됨 핸들러
  const handleSelect = (e: React.ChangeEvent<unknown>, nodeId: string) => {
    setSelected(nodeId);
    const deptItem = orgChartData?.find((x) => x.treeNodeIndex === nodeId);
    setSelectedDept && setSelectedDept(deptItem);
  };

  if (rootNode === undefined) {
    return <></>;
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <TreeView
        className={`${muiClasses.root} msTeamsTheme-${(featureToggles?.theme ? theme : "default") ?? "default"}`}
        defaultCollapseIcon={featureToggles?.newCollapseExpandIcon ? <ExpandMore /> : <IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={featureToggles?.newCollapseExpandIcon ? <ChevronRight /> : <AddBoxOutlined />}
        expanded={featureToggles?.searchv2 === false ? (deptSearchKeyword === "" ? expanded : deptSearchResultExpanded) : searchKeyword === "" ? expanded : searchResultExpanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {featureToggles?.searchv2 === false ? (deptSearchKeyword === "" ? deptTreeItems : deptSearchResultTreeItems) : searchKeyword === "" ? deptTreeItems : searchResultTreeItems}
      </TreeView>
    </ThemeProvider>
  );
};

export default DeptTree;
