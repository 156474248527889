import axios from "axios";

const endpoint = `${window.location.origin}/api`;

export const getGraphAccessToken = (ssoToken: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .get<string>(`${endpoint}/getGraphAccessToken?ssoToken=${ssoToken}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
