import React from "react";
import "./App.css";
import * as microsoftTeams from "@microsoft/teams-js";

/**
 * 이 컴포넌트는 그룹 탭을 위한 사용자 설정 옵션을 표시해 주기 위해 사용됨
 * 여기서 사용자가 필요한 설정값을 입력할 수 있게 해주고, 설정값 입력이 완료되면 무결성을 검증한 뒤 이를 저장하고 팀즈에 설정이 완료되었음을 알려서 저장 버튼이 활성화 되도록 해야합니다.
 */
const TabConfig: React.FC = () => {
  microsoftTeams.initialize(() => {
    /**
     * 탭을 위한 contentUrl은 반드시 설정되어야 하는 필수 값이며, 이 값은 설정된 탭의 소스 URL로 사용됨.
     * 이를 통해 사용자가 선택한 설정에 따라 쿼리 문자열 매개변수를 추가할 수 있음.
     */
    let contentURL = `${window.location.origin}/tab`;
    microsoftTeams.settings.setSettings({ entityId: "somevalue", contentUrl: contentURL });

    /**
     * 사용자가 탭 설정을 올바르게 입력했는지 확인한 후 대화 상자의 상태를 유효하게 설정해야 함.
     * 그러면 구성 대화 상자에서 저장 버튼이 활성화됨.
     */
    microsoftTeams.settings.setValidityState(true);
  });

  return (
    <div>
      <h1>탭 설정</h1>
      <div>여기에 팀 또는 그룹 채팅에 탭을 추가할 때 사용자가 선택할 수 있는 탭 설정 옵션을 추가합니다.</div>
    </div>
  );
};

export default TabConfig;
