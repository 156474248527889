import React from "react";
import { useMediaQuery } from "react-responsive";

const Mobile: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return <>{isMobile && children}</>;
};

const Tablet: React.FC = ({ children }) => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return <>{isTablet && children}</>;
};

const PC: React.FC = ({ children }) => {
  const isPC = useMediaQuery({ query: "(min-width: 1024px)" });
  return <>{isPC && children}</>;
};

const PCWide: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(min-width: 1280px)" });
  return <>{isMobile && children}</>;
};

export { Mobile, Tablet, PC, PCWide };
