import React, { useContext } from "react";
import {
  Flex,
  Table,
  Checkbox,
  Input,
  Button,
  RadioGroup,
  Dialog,
  SettingsIcon,
  Popup,
  StarIcon,
  ContactGroupIcon,
  TranslationIcon,
  InfoIcon,
  Text,
} from "@fluentui/react-northstar";
import { OrgChartPageContext } from "./Tab";

const DemoWidget: React.FC = () => {
  console.log("DemoControlPanel 렌더링됨");

  const { featureToggles, setFeatureToggles, msTeamsContext, initialLocale, locale, setLocale, initialDomain, initialSite, orgChartDataOption, setOrgChartDataOption } = useContext(
    OrgChartPageContext
  );

  const [domain, setDomain] = React.useState(initialDomain ?? "");
  const [site, setSite] = React.useState(initialSite ?? "");
  const [localeOverride, setLocaleOverride] = React.useState("");
  React.useEffect(() => {
    if (setLocale === undefined) return;
    if (initialLocale === undefined) return;

    setLocale(localeOverride === "" ? initialLocale : localeOverride);
  }, [localeOverride, initialLocale, setLocale]);

  if (
    featureToggles === undefined ||
    setFeatureToggles === undefined ||
    msTeamsContext === undefined ||
    locale === undefined ||
    setLocale === undefined ||
    initialDomain === undefined ||
    initialSite === undefined ||
    orgChartDataOption === undefined ||
    setOrgChartDataOption === undefined
  ) {
    return <></>;
  }

  return (
    <Flex padding="padding.medium" style={{ position: "fixed", right: 0, bottom: 0, zIndex: 1, boxShadow: "2rem 2rem 2rem grey" }}>
      <Popup
        content={
          <Flex column gap="gap.small" hAlign="start">
            <Dialog
              header={
                <Flex gap="gap.small" vAlign="center">
                  <StarIcon outline />
                  <Text>기능 On/Off</Text>
                </Flex>
              }
              trigger={<Button icon={<StarIcon />} text content="기능 On/Off" />}
              content={
                <Flex column gap="gap.small" hAlign="start" padding="padding.medium">
                  <Checkbox
                    label="Teams 테마 적용"
                    toggle
                    checked={featureToggles.theme}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, theme: props?.checked ?? false });
                    }}
                  />
                  <Checkbox
                    label="접기/펼치기 아이콘 V2"
                    toggle
                    checked={featureToggles.newCollapseExpandIcon}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, newCollapseExpandIcon: props?.checked ?? false });
                    }}
                  />
                  <Checkbox
                    label="부서 아이콘 V2"
                    toggle
                    checked={featureToggles.newDeptIcon}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, newDeptIcon: props?.checked ?? false });
                    }}
                  />
                  <Checkbox
                    label="검색 V2"
                    toggle
                    checked={featureToggles.searchv2}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, searchv2: props?.checked ?? false });
                    }}
                  />
                  <Checkbox
                    label="반응형 V2"
                    toggle
                    checked={featureToggles.reponsivev2}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, reponsivev2: props?.checked ?? false });
                    }}
                  />
                  <Checkbox
                    label="SSO"
                    toggle
                    checked={featureToggles.sso}
                    onChange={(e, props) => {
                      setFeatureToggles({ ...featureToggles, sso: props?.checked ?? false });
                    }}
                  />
                </Flex>
              }
            />
            <Dialog
              header={
                <Flex gap="gap.small" vAlign="center">
                  <ContactGroupIcon outline />
                  <Text>조직도 데이터 옵션 수동지정</Text>
                </Flex>
              }
              cancelButton="초기화"
              onCancel={() => {
                setDomain(initialDomain);
                setSite(initialSite);
                setOrgChartDataOption({ domain: initialDomain, site: initialSite });
              }}
              confirmButton="적용"
              onConfirm={() => {
                setOrgChartDataOption({ domain: domain, site: site });
              }}
              trigger={<Button icon={<ContactGroupIcon />} text content="조직도 데이터 옵션 수동지정" />}
              content={
                <Flex column gap="gap.small">
                  <Input
                    label="domain"
                    labelPosition="inside"
                    defaultValue={initialDomain}
                    value={domain}
                    onChange={(e, d) => {
                      setDomain(d?.value ?? "");
                    }}
                    fluid
                  />
                  <Input
                    label="site"
                    labelPosition="inside"
                    defaultValue={initialSite}
                    value={site}
                    onChange={(e, d) => {
                      setSite(d?.value ?? "");
                    }}
                    fluid
                  />
                </Flex>
              }
            />
            <Dialog
              header={
                <Flex gap="gap.small" vAlign="center">
                  <TranslationIcon outline />
                  <Text>언어고정</Text>
                </Flex>
              }
              trigger={<Button icon={<TranslationIcon />} text content="언어고정" />}
              content={
                <RadioGroup
                  defaultCheckedValue={localeOverride}
                  items={[
                    { key: "lang0", label: "안함", value: "" },
                    { key: "lang1", label: "한국어", value: "ko" },
                    { key: "lang2", label: "English", value: "en" },
                  ]}
                  onCheckedValueChange={(e, d) => {
                    setLocaleOverride(d?.value?.toString() ?? "");
                  }}
                />
              }
            />
            <Dialog
              header={
                <Flex gap="gap.small" vAlign="center">
                  <InfoIcon outline />
                  <Text>Context 정보</Text>
                </Flex>
              }
              trigger={<Button icon={<InfoIcon />} text content="Context 정보" />}
              content={
                <Table
                  compact
                  header={["KEY", "VALUE"]}
                  rows={Object.entries(msTeamsContext).map(([key, value], i) => {
                    return { key: i, items: [key, value] };
                  })}
                  aria-label="context 정보"
                />
              }
            />
          </Flex>
        }
        trigger={<Button icon={<SettingsIcon />} iconOnly circular title="Demo" />}
      />
    </Flex>
  );
};

export default DemoWidget;
