import axios from "axios";

/**
 * 자격증명 JSON (레거시)
 * @deprecate 하위호환을 유지하기 위한 레거시 타입입니다.
 */
interface PrincipalJsonLegacy {
  LoginName: string;
  PrincipalId: number;
  PrincipalType: number;
  Title: string;
}

/**
 * 조직 JSON 데이터 항목 (레거시)
 * @deprecate 하위호환을 유지하기 위한 레거시 타입입니다.
 */
interface OrgJsonDataItemLegacy {
  DepartmentInfo?: {
    PrincipalJson?: PrincipalJsonLegacy | null;
    Email?: string | null;
  } | null;
  Guid: string;
  Name: string;
  Position: string;
  Type: OrgChartDataItemType;
  UserInfo?: {
    Email?: string | null;
    JobTitle?: string | null;
    MobilePhone?: string | null;
    OfficePhone?: string | null;
    PrincipalJson?: PrincipalJsonLegacy | null;
    Responsibility?: string | null;
  } | null;
  Hide?: boolean | null;
}

/**
 * 자격증명 정보
 */
export interface PrincipalInfo {
  loginName: string;
  principalId: number;
  principalType: number;
  title: string;
}

/**
 * 조직도 데이터 항목 종류
 */
export type OrgChartDataItemType = "Root" | "Department" | "User";

/**
 * 조직도 데이터 항목
 */
export interface OrgChartDataItem {
  guid: string;
  // 트리 노드 인덱스: "0.0.0.0", "0.1.1.1"
  treeNodeIndex: string;
  // 트리 노드 깊이: 0는 루트, 1은 루트의 바로 자식
  treeNodeDepth: number;
  title: string;
  // 항목 타입
  type: OrgChartDataItemType;
  // 부서 정보: 항목 타입이 "Department"인 경우에만 존재함
  deptInfo?: {
    email?: string | null;
    principalInfo?: PrincipalInfo | null;
  } | null;
  // 사용자 정보: 항목 타입이 "User"인 경우에만 존재함
  userInfo?: {
    email?: string | null;
    jobTitle?: string | null;
    mobilePhone?: string | null;
    officePhone?: string | null;
    principalInfo?: PrincipalInfo | null;
    responsibility?: string | null;
  } | null;
  // 숨김 여부: 항목 타입이 "User"인 경우에만 존재함
  hide?: boolean | null;
}

/**
 * 조직도 노드
 */
export interface OrgChartNode {
  guid: string;
  id: string;
  depth: number;
  label: string;
  type: OrgChartDataItemType;
  children?: OrgChartNode[];
}

/**
 * 조직도 최상위 노드
 */
export interface OrgChartRootNode extends OrgChartNode {
  children?: (OrgChartDeptNode | OrgChartUserNode)[];
}
/**
 * 조직도 부서 노드
 */
export interface OrgChartDeptNode extends OrgChartNode {
  email?: string | null;
  principalInfo?: PrincipalInfo | null;
  children?: (OrgChartDeptNode | OrgChartUserNode)[];
}
/**
 * 조직도 사용자 노드
 */
export interface OrgChartUserNode extends OrgChartNode {
  email?: string | null;
  jobTitle?: string | null;
  mobilePhone?: string | null;
  officePhone?: string | null;
  principalInfo?: PrincipalInfo | null;
  responsibility?: string | null;
  hide?: boolean | null;
  children?: OrgChartUserNode[];
}

// 레거시 타입은 카멜 케이스를 사용하지 않으며, 일부 변수명이 부적절한 문제가 있어서 그대로 사용하지 않고 별도 타입으로 변환하여 사용

// 레거시 타입 변환: PrincipalJsonLegacy to PrincipalInfo
function convertLegacyToPrincipalInfo(legacy: PrincipalJsonLegacy): PrincipalInfo {
  return {
    loginName: legacy.LoginName,
    principalId: legacy.PrincipalId,
    principalType: legacy.PrincipalType,
    title: legacy.Title,
  };
}

// 레거시 타입 변환: OrgJsonDataItemLegacy to OrgChartDataItem
function convertLegacyToOrgChartDataItem(legacy: OrgJsonDataItemLegacy): OrgChartDataItem {
  return {
    guid: legacy.Guid,
    treeNodeIndex: legacy.Position,
    // 레거시 타입엔 없던 필드. 편의를 위해 추가됨.
    treeNodeDepth: legacy.Position.split(".").length,
    title: legacy.Name,
    type: legacy.Type,
    deptInfo: legacy.DepartmentInfo && {
      email: legacy.DepartmentInfo.Email,
      principalInfo: legacy.DepartmentInfo.PrincipalJson && convertLegacyToPrincipalInfo(legacy.DepartmentInfo.PrincipalJson),
    },
    userInfo: legacy.UserInfo && {
      email: legacy.UserInfo.Email,
      jobTitle: legacy.UserInfo.JobTitle,
      mobilePhone: legacy.UserInfo.MobilePhone,
      officePhone: legacy.UserInfo.OfficePhone,
      principalInfo: legacy.UserInfo.PrincipalJson && convertLegacyToPrincipalInfo(legacy.UserInfo.PrincipalJson),
      responsibility: legacy.UserInfo.Responsibility,
    },
    hide: legacy.Hide,
  };
}

const endPointUrlDefault = "https://appservicesdslyncwcfservice.azurewebsites.net/SDSLyncWcfService.svc/webbasic";

/**
 * 조직도 데이터 옵션
 */
export interface OrgChartDataOption {
  domain?: string;
  site?: string;
  endPointUrl?: string;
  includeHiddenItems?: boolean;
}

/**
 * 평면 구조의 조직도 데이터를 가져옵니다.
 * @param option 조직도 데이터 옵션
 */
export const getOrgChartData = (option: OrgChartDataOption): Promise<OrgChartDataItem[]> => {
  return new Promise((resolve, reject) => {
    const domain = option?.domain?.trim() ?? "";
    const site = option?.site?.trim() ?? "";
    const endPointUrl = option?.endPointUrl ?? endPointUrlDefault;
    const includeHiddenItems = option?.includeHiddenItems ?? false;

    axios
      .post(
        `${endPointUrl}/GetOrgJsonData`,
        {
          domain: domain,
          site: site,
        },
        { headers: { "Content-Type": "application/json; charset=utf-8" } }
      )
      .then((response) => {
        try {
          const dataLegacy: OrgJsonDataItemLegacy[] = JSON.parse(response?.data?.GetOrgJsonDataResult?.Msg ?? "[]");

          let data = dataLegacy.map((x) => convertLegacyToOrgChartDataItem(x));

          // 숨김 아이템을 포함하지 않는 경우
          if (!includeHiddenItems) {
            // 숨김 아이템 제외
            data = data.filter((x) => !x.hide);
          }

          resolve(data);
        } catch (error) {
          reject(new Error(`조직도 데이터 가져오기 - 리턴 데이터 파싱 오류: ${error}`));
        }
      })
      .catch((error) => {
        reject(new Error(`조직도 데이터 가져오기 오류: ${error}`));
      });
  });
};

/**
 * 평면 구조의 조직도 데이터를 트리 구조의 노드로 변환합니다.
 * @param data 조직도 데이터
 */
export const convertOrgChartDataToNodes = (data: OrgChartDataItem[]): OrgChartRootNode => {
  // 최상위 항목
  const rootItem = data.find((x) => x.type === "Root");

  // 만약 조직도 데이터에 최상위 항목이 없는 경우, 에러 투척
  if (rootItem === undefined) {
    throw new Error("조직도 데이터에 최상위(Root) 노드가 존재하지 않음");
  }

  function getChildItems(item: OrgChartDataItem) {
    return data.filter((x) => x.treeNodeIndex.startsWith(`${item.treeNodeIndex}.`) && x.treeNodeDepth === item.treeNodeDepth + 1);
  }
  function getCommonProps(item: OrgChartDataItem) {
    const node: OrgChartNode = {
      guid: item.guid,
      id: item.treeNodeIndex,
      depth: item.treeNodeDepth,
      label: item.title,
      type: item.type,
    };
    return node;
  }
  function getRootNode(item: OrgChartDataItem) {
    const rootNode: OrgChartRootNode = {
      ...getCommonProps(item),
    };

    const childNodes = getChildItems(item).map((x): OrgChartDeptNode | OrgChartUserNode => {
      switch (x.type) {
        case "Department":
          return getDeptNode(x);
        case "User":
          return getUserNode(x);
        default:
          throw new Error(`최상위 노드 하단에 예상하지 못한 타입의 노드가 존재함(${x.type})`);
      }
    });

    if (childNodes.length > 0) {
      rootNode.children = childNodes;
    }

    return rootNode;
  }
  function getDeptNode(item: OrgChartDataItem) {
    const deptNode: OrgChartDeptNode = {
      ...getCommonProps(item),
      email: item.deptInfo?.email,
      principalInfo: item.deptInfo?.principalInfo,
    };

    const childNodes = getChildItems(item).map((x): OrgChartDeptNode | OrgChartUserNode => {
      switch (x.type) {
        case "Department":
          return getDeptNode(x);
        case "User":
          return getUserNode(x);
        default:
          throw new Error(`부서 노드 하단에 예상하지 못한 타입의 노드가 존재함(${x.type})`);
      }
    });

    if (childNodes.length > 0) {
      deptNode.children = childNodes;
    }

    return deptNode;
  }
  function getUserNode(item: OrgChartDataItem) {
    const userNode: OrgChartUserNode = {
      ...getCommonProps(item),
      email: item.userInfo?.email,
      jobTitle: item.userInfo?.jobTitle,
      mobilePhone: item.userInfo?.mobilePhone,
      officePhone: item.userInfo?.officePhone,
      principalInfo: item.userInfo?.principalInfo,
      responsibility: item.userInfo?.responsibility,
      hide: item.hide,
    };

    const childNodes = getChildItems(item).map(
      (x): OrgChartUserNode => {
        switch (x.type) {
          case "User":
            return getUserNode(x);
          default:
            throw new Error(`사용자 노드 하단에 예상하지 못한 타입의 노드가 존재함(${x.type})`);
        }
      }
    );

    if (childNodes.length > 0) {
      userNode.children = childNodes;
    }

    return userNode;
  }

  return getRootNode(rootItem);
};

/**
 * 조직도 데이터를 트리구조의 노드 형태로 가져옵니다.
 * @param option 조직도 데이터 옵션
 */
export const getDataAsNodes = (option: OrgChartDataOption): Promise<OrgChartRootNode> => {
  return new Promise((resolve, reject) => {
    getOrgChartData(option)
      .then((data) => resolve(convertOrgChartDataToNodes(data)))
      .catch((err) => reject(err));
  });
};
