import React from "react";
import "./App.css";
import crypto from "crypto";
import * as microsoftTeams from "@microsoft/teams-js";

/**
 * 이 컴포넌트는 사용자를 팝업창에서 Azure 인증 엔드포인트로 리다이렉트 하기 위해 사용됨
 */
const ConsentPopup: React.FC = () => {
  React.useEffect(() => {
    // Microsoft Teams SDK 초기화
    microsoftTeams.initialize();

    // 테넌트ID 추출을 위해 사용자 컨텍스트 확보
    microsoftTeams.getContext((context) => {
      // 로그인 된 사용자의 테넌트ID
      const tenant = context["tid"];
      // Azure AD에 등록된 앱의 Client ID (멀티테넌트 앱일 경우 다른 테넌트에 등록된 앱일수도 있음)
      const client_id = process.env.REACT_APP_AAD_APP_CLIENT_ID;

      const url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?`;

      // 암시적 권한 허용 인증 흐름을 위한 쿼리 구축
      // 참고: https://docs.microsoft.com/ko-kr/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
      let queryParams = new URLSearchParams({
        tenant: `${tenant}`,
        client_id: `${client_id}`,
        // 다른 샘플들에서의 token_id는 오직 open ID를 사용할 때만 필요로 함
        response_type: "token",
        scope: "https://graph.microsoft.com/User.Read",
        redirect_uri: window.location.origin + "/auth-end",
        nonce: crypto.randomBytes(16).toString("base64"),
      }).toString();
      let authorizeEndpoint = url + queryParams;

      // Azure 인증 엔드포인트로 리다이렉트. 흐름이 완료되면 사용자는 auth-end 로 향하게 될 것임 - ClosePopup 컴포넌트로 향함
      window.location.assign(authorizeEndpoint);
    });
  }, []);

  return (
    <div>
      <h1>사용자 동의 페이지로 리다이렉트중...</h1>
    </div>
  );
};

export default ConsentPopup;
