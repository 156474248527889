import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import ConsentPopup from "./ConsentPopup";
import ClosePopup from "./ClosePopup";
import TabConfig from "./TabConfig";

/**
 * 초기화 및 라우팅을 담당하는 메인 앱
 */
const App: React.FC = () => {
  // 팀즈에서 표시될 페이지 라우팅
  return (
    <Router>
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/termsofuse" component={TermsOfUse} />
      <Route exact path="/tab" component={Tab} />
      <Route exact path="/config" component={TabConfig} />
      <Route exact path="/auth-start" component={ConsentPopup} />
      <Route exact path="/auth-end" component={ClosePopup} />
    </Router>
  );
};

export default App;
