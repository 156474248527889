export type FeatureToggles = {
  theme: boolean;
  newCollapseExpandIcon: boolean;
  newDeptIcon: boolean;
  searchv2: boolean;
  reponsivev2: boolean;
  sso: boolean;
  profileImage: boolean;
  useGraphUserData: boolean;
  useGraphUserDataFirst: boolean;
};

export const featureTogglesDefault: FeatureToggles = {
  theme: false,
  newCollapseExpandIcon: false,
  newDeptIcon: false,
  searchv2: false,
  reponsivev2: false,
  sso: false,
  profileImage: false,
  useGraphUserData: false,
  useGraphUserDataFirst: false,
};
