// URL 쿼리 파라미터 값 가져오기
export const getUrlParmValue = (name: string): string | undefined => {
  // URL 쿼리에서 ? 를 제외한 값
  const querystring = window.location.search.substring(1);
  const paramChunks = querystring.split("&");
  for (const paramPairs of paramChunks) {
    const [paramName, paramValue] = paramPairs.split("=");
    if (decodeURIComponent(paramName).toLowerCase() === name.toLowerCase()) {
      return decodeURIComponent(paramValue);
    }
  }
  return undefined;
};
