import React from "react";
import { Input, SearchIcon } from "@fluentui/react-northstar";
import { OrgChartPageContext } from "./Tab";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const SearchV2: React.FC = () => {
  const { setSearchKeyword } = React.useContext(OrgChartPageContext);
  const { t } = useTranslation();

  const onChangeHandler = debounce((value: string) => {
    setSearchKeyword && setSearchKeyword(value);
  }, 200);

  return <Input icon={<SearchIcon />} fluid clearable placeholder={t("부서 및 사용자 검색...")} onChange={(e, v) => onChangeHandler(v?.value ?? "")} />;
};

export default SearchV2;
