import React from "react";
import "./App.css";

/**
 * 이 컴포넌트는 정보 탭의 링크에서 찾을 수 있는 "사용약관" 을 표시하는데 사용됨.
 */
const TermsOfUse: React.FC = () => {
  return (
    <div>
      <h1>Terms of Use</h1>
    </div>
  );
};

export default TermsOfUse;
