import React from "react";
import { Flex, Segment, Provider, Loader, Text, ShorthandValue, ShorthandCollection, DropdownItemProps, ErrorIcon, InfoIcon, Button } from "@fluentui/react-northstar";
import "./App.css";
import * as microsoftTeams from "@microsoft/teams-js";
import * as msTeams from "../msTeams";
import * as utils from "../utils";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { resources as i18nResources } from "../locales/i18n";
import { OrgChartDataOption, OrgChartDataItem, getOrgChartData, OrgChartRootNode, convertOrgChartDataToNodes } from "../../core/orgChart";
import * as api from "../api";
import { FeatureToggles, featureTogglesDefault } from "../../core/featureToggles";
import { AxiosError } from "axios";
import { Mobile, Tablet } from "../mediaQuery";
import DeptTree from "./DeptTree";
import DeptUsers from "./DeptUsers";
import DemoWidget from "./DemoWidget";
import SearchDept from "./SearchDept";
import SearchV2 from "./SearchV2";
import InviteChat from "./InviteChat";

// 개발 환경 여부
const isDevEnv = process.env.NODE_ENV === "development";

// i18n 초기화
i18n.use(initReactI18next).init({
  resources: i18nResources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  debug: true,
});

// 조직도 페이지 컨텍스트
export const OrgChartPageContext = React.createContext<{
  isDevEnv?: boolean;
  featureToggles?: FeatureToggles;
  setFeatureToggles?: React.Dispatch<React.SetStateAction<FeatureToggles>>;
  msTeamsContext?: microsoftTeams.Context;
  initialLocale?: string;
  setInitialLocale?: React.Dispatch<React.SetStateAction<string | undefined>>;
  locale?: string;
  setLocale?: React.Dispatch<React.SetStateAction<string | undefined>>;
  theme?: string;
  initialDomain?: string;
  initialSite?: string;
  orgChartDataOption?: OrgChartDataOption;
  setOrgChartDataOption?: React.Dispatch<React.SetStateAction<OrgChartDataOption>>;
  orgChartData?: OrgChartDataItem[];
  rootNode?: OrgChartRootNode;
  searchKeyword?: string;
  setSearchKeyword?: React.Dispatch<React.SetStateAction<string>>;
  deptSearchKeyword?: string;
  setDeptSearchKeyword?: React.Dispatch<React.SetStateAction<string>>;
  selectedDept?: OrgChartDataItem;
  setSelectedDept?: React.Dispatch<React.SetStateAction<OrgChartDataItem | undefined>>;
  chatUsers?: ShorthandValue<DropdownItemProps> | ShorthandCollection<DropdownItemProps>;
  setChatUsers?: React.Dispatch<React.SetStateAction<ShorthandValue<DropdownItemProps> | ShorthandCollection<DropdownItemProps>>>;
  chatTopicName?: string | number;
  setChatTopicName?: React.Dispatch<React.SetStateAction<React.ReactText>>;
}>({});

/**
 * 이 탭 컴포넌트는 앱의 주요 컨텐츠를 렌더링 하기위해 사용됨
 */
const Tab: React.FC = () => {
  console.log("Tab 컴포넌트 로드됨");

  // 로컬 스토리지에 저장된 상태객체
  const localStates: {
    orgChartDataOption?: OrgChartDataOption;
    featureToggles?: FeatureToggles;
  } = JSON.parse(localStorage.getItem("states") ?? "{}");

  const [featureToggles, setFeatureToggles] = React.useState<FeatureToggles>(localStates.featureToggles ?? featureTogglesDefault);
  const [msTeamsContext, setMsTeamsContext] = React.useState<microsoftTeams.Context>();
  const [graphAccessToken, setGraphAccessToken] = React.useState<string>("");
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isConsentRequired, setIsConsentRequired] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [authToken, setAuthToken] = React.useState<string>("");
  const [theme, setTheme] = React.useState<string>();
  const [initialDomain, setInitialDomain] = React.useState("");
  const [initialSite, setInitialSite] = React.useState("");
  const [orgChartDataOption, setOrgChartDataOption] = React.useState<OrgChartDataOption>(localStates.orgChartDataOption ?? {});
  const [orgChartData, setOrgChartData] = React.useState<OrgChartDataItem[]>();
  const [rootNode, setRootNode] = React.useState<OrgChartRootNode>();
  const [initialLocale, setInitialLocale] = React.useState<string>();
  const [locale, setLocale] = React.useState<string>();
  const [selectedDept, setSelectedDept] = React.useState<OrgChartDataItem>();
  // 검색어
  const [searchKeyword, setSearchKeyword] = React.useState("");
  // 부서 검색어
  const [deptSearchKeyword, setDeptSearchKeyword] = React.useState("");

  const [chatUsers, setChatUsers] = React.useState<ShorthandValue<DropdownItemProps> | ShorthandCollection<DropdownItemProps>>();
  const [chatTopicName, setChatTopicName] = React.useState<string | number>("");

  const { t, i18n } = useTranslation();

  const onThemeChangeHandler = (theme: string) => {
    setTheme(theme);
  };

  // 초기화: 한번만 실행
  React.useEffect(() => {
    msTeams
      .initializeSDK()
      .then(() => {
        return msTeams.getContext();
      })
      .then((context) => {
        setMsTeamsContext(context);

        // 도메인: 값 우선순위 = URL 파라미터 (f_domain) -> 컨텍스트의 loginHint @ 뒷부분
        const domain = utils.getUrlParmValue("f_domain") ?? (context.loginHint && (context.loginHint.includes("@") ? context.loginHint.split("@")?.pop() ?? "" : "")) ?? "";
        if (domain === "") throw new Error("조직도 데이터 확보에 필요한 정보를 확보하지 못했습니다.(domain)");
        setInitialDomain(domain);

        // 사이트: 값 우선순위: URL 파라미터 (site) -> 개발환경일 경우 "mailnara.sharepoint.com/sites/portal"
        const site = utils.getUrlParmValue("site") ?? (isDevEnv ? "mailnara.sharepoint.com/sites/portal" : "");
        if (site === "") throw new Error("조직도 데이터 확보에 필요한 정보를 확보하지 못했습니다.(site)");
        setInitialSite(site);

        // 조직도 데이터 옵션 확보
        setOrgChartDataOption({ domain: domain, site: site });

        // 로케일: URL 파라미터(f_*) -> URL 파라미터 -> 컨텍스트
        const locale = utils.getUrlParmValue("f_locale") ?? utils.getUrlParmValue("locale") ?? context.locale;
        setInitialLocale(locale);
        setLocale(locale);

        // 테마: URL 파라미터(f_*) -> URL 파라미터 -> 컨텍스트
        const theme = utils.getUrlParmValue("f_theme") ?? utils.getUrlParmValue("theme") ?? context.theme;
        setTheme(theme);
        microsoftTeams.registerOnThemeChangeHandler(onThemeChangeHandler);

        if (featureToggles.sso) {
          return msTeams.getAuthToken();
        }
      })
      .then((authToken) => {
        if (authToken === undefined) return;

        setAuthToken(authToken);
      })
      .catch((error) => {
        setError(`탭 페이지 초기화 실패: ${error}`);
        setIsError(true);
      });
  }, []);

  // 로케일 변경 처리: 로케일이 변경될 때마다 실행
  React.useEffect(() => {
    i18n.changeLanguage(locale ?? "en");
  }, [locale, i18n]);

  // 조직도 데이터 확보: 조직도 데이터 옵션 변경시 마다 실행
  React.useEffect(() => {
    const domain = orgChartDataOption.domain?.trim() ?? "";
    const site = orgChartDataOption.site?.trim() ?? "";

    // domain 또는 site 값이 없으면 아무것도 안함
    if (domain === "" || site === "") return;

    getOrgChartData({ domain: domain, site: site, ...orgChartDataOption })
      .then((data) => {
        setOrgChartData(data);
        const rootNode = convertOrgChartDataToNodes(data);
        setRootNode(rootNode);
        setError("");
        setIsError(false);
      })
      .catch((error) => {
        setError(error);
        setIsError(true);
      });
  }, [orgChartDataOption]);

  // 상태저장: 조직도 데이터 옵션 또는 기능 토글 값이 변경될 때 마다 실행
  React.useEffect(() => {
    console.log("로컬 스토리지 상태 저장 호출됨");
    localStorage.setItem("states", JSON.stringify({ featureToggles, orgChartDataOption }));
  }, [featureToggles, orgChartDataOption]);

  // 팀즈 인증 토큰 변경시: 그래프 엑세스 토큰 확보
  React.useEffect(() => {
    if (authToken === "") return;

    // 그래프 엑세스 토큰 확보
    api
      .getGraphAccessToken(authToken)
      .then((result) => {
        setGraphAccessToken(result);
      })
      .catch((error: AxiosError<{ error: string }>) => {
        // 대화형 로그인이 필요할 경우
        if (error.response?.data?.error === "consent_required") {
          setIsConsentRequired(true);
        } else {
          setError(`인증 토큰 획득 실패 ${error}`);
          setIsError(true);
        }
      });
  }, [authToken]);

  // 로그인 버튼 클릭 핸들러
  const loginButtonClickHandler = () => {
    microsoftTeams.authentication.authenticate({
      url: window.location.origin + "/auth-start",
      width: 600,
      height: 535,
      successCallback: (result) => {
        if (result !== undefined) {
          setGraphAccessToken(result);
        }

        setError("대화형 로그인을 통해 그래프 엑세스 토큰 확보 실패: 인증에 성공했으나 result가 undefined.");
        setIsError(true);
      },
      failureCallback: (reason) => {
        setError(`대화형 로그인을 통해 그래프 엑세스 토큰 확보 실패: ${reason ?? "reason이 undefined"}`);
        setIsError(true);
      },
    });
  };

  // 조직도 페이지 컨텍스트 값들
  const orgChartPageContextValues = {
    isDevEnv,
    featureToggles,
    setFeatureToggles,
    msTeamsContext,
    initialLocale,
    setInitialLocale,
    locale,
    setLocale,
    theme,
    initialDomain,
    initialSite,
    orgChartDataOption,
    setOrgChartDataOption,
    orgChartData,
    rootNode,
    searchKeyword,
    setSearchKeyword,
    deptSearchKeyword,
    setDeptSearchKeyword,
    selectedDept,
    setSelectedDept,
    chatUsers,
    setChatUsers,
    chatTopicName,
    setChatTopicName,
  };

  // 팀즈 테마
  const teamsTheme = featureToggles.theme ? msTeams.getTheme(theme) : msTeams.getTheme();

  // 에러 스크린
  const errorScreen = (
    <Provider theme={teamsTheme}>
      <Flex fill hAlign="center" vAlign="center" style={{ minHeight: "100vh" }}>
        <Flex column gap="gap.large">
          <ErrorIcon size="largest" />
          <Text size="larger">{t("앗, 이런!")}</Text>
          <Text error>{t("페이지를 표시하는 도중 문제가 발생했습니다.")}</Text>
          <Segment color="red">
            <Text temporary>{error}</Text>
          </Segment>
        </Flex>
      </Flex>
      <OrgChartPageContext.Provider value={orgChartPageContextValues}>
        <DemoWidget />
      </OrgChartPageContext.Provider>
    </Provider>
  );

  // 사용자 로그인 필요 스크린
  const consentRequiredScreen = (
    <Provider theme={teamsTheme}>
      <Flex fill hAlign="center" vAlign="center" style={{ minHeight: "100vh" }}>
        <Flex column gap="gap.large">
          <InfoIcon size="largest" />
          <Text size="larger">{t("로그인 필요")}</Text>
          <Text>{t("SSO 로그인 하지 못했습니다. 앱 사용 동의가 필요하거나 관리자가 MFA를 사용하도록 설정한 것 같습니다.")}</Text>
          <Segment color="gray">
            <Button onClick={loginButtonClickHandler}>{t("로그인")}</Button>
          </Segment>
        </Flex>
      </Flex>
      <OrgChartPageContext.Provider value={orgChartPageContextValues}>
        <DemoWidget />
      </OrgChartPageContext.Provider>
    </Provider>
  );

  // 로딩 화면
  const loading = (
    <Flex fill hAlign="center" style={{ minHeight: "100vh" }}>
      <Loader label={t("로딩중...")} />
    </Flex>
  );

  // 반응형v1 화면
  const responsivev1 = (
    <>
      <DemoWidget />
      <Flex fill style={{ minHeight: "100vh" }}>
        <Flex column styles={{ minWidth: "30vw" }}>
          <Segment>
            <Flex column gap="gap.medium">
              {featureToggles?.searchv2 === false ? <SearchDept /> : <SearchV2 />}
              <Flex.Item grow>
                <DeptTree />
              </Flex.Item>
            </Flex>
          </Segment>
        </Flex>
        <Flex.Item grow>
          <Segment>
            <Flex column gap="gap.large">
              <DeptUsers />
              <InviteChat />
            </Flex>
          </Segment>
        </Flex.Item>
      </Flex>
    </>
  );

  // 반응형v2 화면
  const responsivev2 = (
    <>
      <Tablet>
        <DemoWidget />
        <Flex fill style={{ minHeight: "100vh" }}>
          <Flex column styles={{ minWidth: "30vw" }}>
            <Segment>
              <Flex column gap="gap.medium">
                {featureToggles?.searchv2 === false ? <SearchDept /> : <SearchV2 />}
                <Flex.Item grow>
                  <DeptTree />
                </Flex.Item>
              </Flex>
            </Segment>
          </Flex>
          <Flex.Item grow>
            <Segment>
              <Flex column gap="gap.large">
                <DeptUsers />
                <InviteChat />
              </Flex>
            </Segment>
          </Flex.Item>
        </Flex>
      </Tablet>
      <Mobile>
        <DemoWidget />
        <Flex fill style={{ minHeight: "100vh" }}>
          <Flex column fill gap="gap.small" style={{ margin: "1vh" }}>
            {featureToggles?.searchv2 === false ? <SearchDept /> : <SearchV2 />}
            <DeptTree />
            <DeptUsers />
            <InviteChat />
          </Flex>
        </Flex>
      </Mobile>
    </>
  );

  return (
    <Provider theme={teamsTheme}>
      {isError ? (
        errorScreen
      ) : isConsentRequired ? (
        consentRequiredScreen
      ) : msTeamsContext === undefined ? (
        <></>
      ) : orgChartData === undefined ? (
        loading
      ) : (
        <OrgChartPageContext.Provider value={orgChartPageContextValues}>{featureToggles.reponsivev2 ? responsivev2 : responsivev1}</OrgChartPageContext.Provider>
      )}
    </Provider>
  );
};

export default Tab;
