import React from "react";
import { Button, Divider, Dropdown, Flex, Input } from "@fluentui/react-northstar";
import { OrgChartPageContext } from "./Tab";
import { useTranslation } from "react-i18next";
import * as msTeams from "../msTeams";

const InviteChat: React.FC = () => {
  const { chatUsers, setChatUsers, chatTopicName, setChatTopicName } = React.useContext(OrgChartPageContext);
  const { t } = useTranslation();

  return (
    <Flex column>
      <Dropdown
        multiple
        fluid
        open={false}
        clearable
        placeholder={t("초대할 사용자를 선택해 주신 후, 채팅 초대 버튼을 눌러주세요.")}
        value={chatUsers}
        onChange={(e, d) => {
          console.log("Dropdown Value", d?.value);
          setChatUsers && setChatUsers(d?.value);
        }}
      />
      <Divider />
      <Flex gap="gap.small">
        <Flex.Item size="size.half">
          <Input
            name="chatTitle"
            fluid
            clearable
            placeholder={t("대화명을 입력하지 않아도 대화를 시작할 수 있습니다.")}
            value={chatTopicName}
            onChange={(e, d) => {
              setChatTopicName && setChatTopicName(d?.value ?? "");
            }}
          />
        </Flex.Item>
        <Button
          primary
          content={t("채팅 초대")}
          disabled={chatUsers === undefined || (chatUsers !== undefined && (chatUsers as { key: string }[]).length === 0)}
          onClick={() => {
            if (chatUsers === undefined) return;
            const users = (chatUsers as { content: string }[]).map((x) => x.content);
            const topicName = chatTopicName?.toString();

            msTeams.executeChatDeepLink(users, topicName);
          }}
        />
        <Button
          content={t("모두 삭제")}
          disabled={chatUsers === undefined || (chatUsers !== undefined && (chatUsers as { key: string }[]).length === 0)}
          onClick={() => {
            setChatUsers && setChatUsers([]);
            setChatTopicName && setChatTopicName("");
          }}
        />
      </Flex>
    </Flex>
  );
};

export default InviteChat;
