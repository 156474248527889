import * as microsoftTeams from "@microsoft/teams-js";
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme, ThemePrepared } from "@fluentui/react-northstar";

// 타임아웃 기본값: 30초
const timeoutDefault = 30000;

/**
 * 팀즈 SDK를 초기화 합니다.
 * @param timeout 타임 아웃 시간(ms)
 */
export const initializeSDK = (timeout = timeoutDefault): Promise<void> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`팀즈 SDK 초기화 오류: 타임아웃(${timeout})`));
    }, timeout);

    microsoftTeams.initialize(() => {
      clearTimeout(timeoutId);
      resolve();
    });
  });
};

/**
 * 팀즈 컨텍스트를 가져옵니다.
 * @param timeout 타임 아웃 시간(ms)
 */
export const getContext = (timeout = timeoutDefault): Promise<microsoftTeams.Context> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`팀즈 컨텍스트 가져오기 오류: 타임아웃(${timeout})`));
    }, timeout);

    initializeSDK(timeout)
      .then(() => {
        microsoftTeams.getContext((context) => {
          resolve(context);
        });
      })
      .catch((error) => {
        reject(new Error(`팀즈 컨텍스트 가져오기 오류: ${error}`));
      })
      .finally(() => {
        clearTimeout(timeoutId);
      });
  });
};

/**
 * 앱을 대신해서 발행 될 Azure AD 토큰을 요청합니다. 만약 아직 만료되지 않았다면 토큰은 캐시로 부터 가져오며, 그렇지 않다면 새 토큰을 얻기 위한 요청이 Azure AD로 전송됩니다.
 * @param resources An optional list of resource for which to acquire the access token; only used for full trust apps.
 * @param claims An optional list of claims which to pass to AAD when requesting the access token.
 * @param silent An optional flag indicating whether to attempt the token acquisition silently or allow a prompt to be shown.
 */
export const getAuthToken = (resources?: string[], claims?: string[], silent?: boolean): Promise<string> => {
  return new Promise((resolve, reject) => {
    initializeSDK()
      .then(() => {
        microsoftTeams.authentication.getAuthToken({
          resources: resources,
          claims: claims,
          silent: silent,
          successCallback: (result: string) => {
            resolve(result);
          },
          failureCallback: (error: string) => {
            reject(error);
          },
        });
      })
      .catch((error) => {
        reject(new Error(`인증 토큰 가져오기 오류: ${error}`));
      });
  });
};

/**
 * 테마 문자열로 Fluent UI 용 테마 객체를 가져옵니다.
 * @param themeString 테마 문자열. "dark", "contrast", "default" 중 하나.
 */
export const getTheme = (themeString = "default"): ThemePrepared => {
  switch (themeString.toLowerCase()) {
    case "dark":
      return teamsDarkTheme;
    case "contrast":
      return teamsHighContrastTheme;
    case "default":
    default:
      return teamsTheme;
  }
};

/**
 * 채팅 딥링크를 실행합니다.
 * @param users 채팅에 초대할 사용자들. 현재는 Azure AD UserPrincipalName 만 지원되며 보통 이메일 주소 형식임.
 * @param topicName 채팅 제목
 * @param message 채팅 메시지
 */
export const executeChatDeepLink = (users: string | string[], topicName = "", message = ""): Promise<void> => {
  return new Promise((resolve, reject) => {
    initializeSDK()
      .then(() => {
        users = Array.isArray(users) ? users.join(",") : users;

        // https://docs.microsoft.com/en-us/microsoftteams/platform/concepts/build-and-test/deep-links#generating-a-deep-link-to-a-chat
        const deepLink = encodeURI(
          `https://teams.microsoft.com/l/chat/0/0?users=${users}${topicName !== "" ? `&topicName=${topicName}` : ""}${message !== "" ? `&message=${message}` : ""}`
        );

        microsoftTeams.executeDeepLink(deepLink, (status, reason) => {
          if (status === false) {
            reject(new Error(`채팅 딥링크 실행 실패(reason:${reason})(args:${JSON.stringify({ users, topicName, message })})`));
            return;
          }
          resolve();
        });
      })
      .catch((err) => reject(err));
  });
};
